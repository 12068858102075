import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  IChangeEmailSaga,
  IConfirmTransferAction,
  IGetAdminDataSaga,
  IReceiveFundsAction,
  IRestoreNodeAction,
  IShowToastMessageAction,
  IToggleNotificationWarningSaga,
  IToggleServerDeletionAction,
  IToggleServerMountingAction,
  IVerifyAccountToTransferFundsAction,
  resetRestorationLoadingId,
  setAdminData,
  setDifferentAccountToTransferFunds,
  setIsAdminCodeValid,
  showTransferFundsCodeField,
  stopLoadingAdminCode,
  stopLoadingOnPromoCode,
  stopLoadingWarningMail,
  toggleChangeEmailLoading,
  toggleChargeNodesLoading,
  toggleConfirmTransferFundsLoading,
  toggleDifferentAccountVerificationLoading,
  toggleLoadingAdminData,
  toggleLoadingDecommissionData,
  toggleLoadingOnResetIndex,
  toggleNodeDeletionLoading,
  setShouldShowEmailChangeCodeField,
  updateEmailAddress,
  updateNotificationWarning,
  userActionTypes,
  IDeleteDecommissionItemAction,
  toggleLoadingDecommissionItem,
  removeDecommissionItem,
  toggleOnchainPaymentLinkLoading,
  setOnchainPaymentLinkData,
  ICreateOnchainPaymentLinkAction,
  IDismissPromotionModalSaga,
  IExecuteNodeDeletionAction,
  toggleReadyNodeDeletionLoading,
} from './actions';
import { pushLoadingAppDataRequest, spliceLoadingAppDataRequest } from '../app/actions';

import api from 'services/api';
import history from 'services/history';
import {
  displayToastMessageWithElements,
  resetIndexToastMessage,
  toastMessageWithElement,
  transferredFundsMessage,
} from './elements';

import {
  updateProfileRequest,
  updateMasternodesToStartNotificationRequest,
  sendPromotionCodeRequest,
  getAdminDataRequest,
  submitAdminCodeRequest,
  resetIndexToDeleteRequest,
  toggleNotificationWarningRequest,
  INotificationWarningData,
  restoreNodeRequest,
  verifyDifferentAccountEmailRequest,
  IDifferentAccountData,
  confirmTransferFundsRequest,
  deleteDecommissionDataRequest,
  updateServerMounting,
  updateServerDeletion,
  chargeNodes,
  deleteNodesRequest,
  changeEmailRequest,
  deleteDecommissionItemRequest,
  createOnchainPaymentLinkRequest,
  dismissPromotionModalRequest,
} from './api';

import {
  updateProfileSuccess,
  updateProfileFailure,
  updateProfileMasternodeToStartNotificationSuccess,
  setNodeData,
  setUserBalances,
  IUpdateProfileAction,
  ISendPromotionCodeAction,
} from './actions';
import { IAdminData, IState } from '../interfaces';
import { getAdminCode, getUserProfile } from './selectors';

const TRANSFER_FUNDS_CODE_REGEX = /confirm the code/gim;
const CHANGE_EMAIL_CODE_REGEX = /confirm the email change/gim;

export function* updateProfile({ payload }: IUpdateProfileAction) {
  try {
    const { name, email, ...rest } = payload.data;

    const profile = {
      name,
      email,
      ...(rest.oldPassword ? rest : {}),
    };

    const response: Object = yield call(updateProfileRequest, profile);

    toast.success('Profile success updated!');

    yield put(updateProfileSuccess(response));
  } catch (err) {
    const response: any = { err };

    toast.error(response.err.response.data.error);
    yield put(updateProfileFailure());
  }
}

export function* updateMasternodesToStartNotification({ payload }: any) {
  try {
    const { data } = payload;

    const { masternode_to_start_notification } = data;

    yield call(updateMasternodesToStartNotificationRequest, data);

    yield put(updateProfileMasternodeToStartNotificationSuccess(masternode_to_start_notification));

    if (masternode_to_start_notification) {
      toast.success('You will get email notifications when you have nodes to be enabled.', {
        autoClose: 5000,
      });
    } else {
      toast.success("You won't get email notifications when you have nodes to be enabled.", {
        autoClose: 5000,
      });
    }
  } catch (err) {
    toast.error('Something went wrong.');
  }
}

export function* getNodeDataSaga() {
  const requestName = 'getNodeDataSaga';
  try {
    yield put(pushLoadingAppDataRequest(requestName));
    const { data } = yield call(api.get, 'masternodes/general');
    yield put(setNodeData(data));
  } catch (err) {
    toast.error('Something went wrong getting node data.');
  } finally {
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

export function* getAdminDataSaga({ payload }: IGetAdminDataSaga) {
  const { shouldShowToastMessage } = payload;
  const state: IState = yield select();
  const userProfile = getUserProfile(state);
  const { admin } = userProfile;
  if (!admin) {
    return;
  }
  const requestName = 'getAdminDataSaga';
  try {
    yield put(toggleLoadingAdminData());
    yield put(pushLoadingAppDataRequest(requestName));
    const adminData: IAdminData = yield call(getAdminDataRequest);
    yield put(setAdminData(adminData));
    if (shouldShowToastMessage) {
      toast.success(`Successfully Refreshed Admin Data!`);
    }
  } catch (err) {
    console.error('Something went wrong on getAdminDataSaga.');
  } finally {
    yield put(stopLoadingWarningMail());
    yield put(spliceLoadingAppDataRequest(requestName));
  }
}

export function* toggleNotificationWarningSaga({ payload }: IToggleNotificationWarningSaga) {
  const state: IState = yield select();
  const userProfile = getUserProfile(state);
  const { admin } = userProfile;
  if (!admin) {
    return;
  }
  const { warningMailType } = payload;
  try {
    const notificationWarningData: INotificationWarningData = yield call(
      toggleNotificationWarningRequest,
      warningMailType
    );

    yield put(updateNotificationWarning(notificationWarningData));
    toast.success(`Successfully updated setting state!`);
  } catch (err) {
    toast.error(`Something went wrong updating notification state.`);
    console.error('Something went wrong on toggleNotificationWarningSaga.');
  }
}

export function* getUserBalances({ payload }: any) {
  const { shouldUseLoadingAppData } = payload;
  const requestName = 'getUserBalances';
  if (shouldUseLoadingAppData) {
    yield put(pushLoadingAppDataRequest(requestName));
  }
  try {
    const { data } = yield call(api.get, 'users');
    const { balance, bonus } = data;
    yield put(setUserBalances({ balance, bonus }));
  } catch (err) {
    console.error(`Something went wrong on getUserBalances`);
  } finally {
    if (shouldUseLoadingAppData) {
      yield put(spliceLoadingAppDataRequest(requestName));
    }
  }
}

export function* sendPromotionCodeSaga({ payload }: ISendPromotionCodeAction) {
  const { promotionCode } = payload;

  try {
    yield call(sendPromotionCodeRequest, promotionCode);
    toast.success('Promotion code successfully activated!', {
      autoClose: 7000,
    });
  } catch (err) {
    console.error(`Something went wrong on sendPromotionCodeSaga`);
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error);
  } finally {
    yield put(stopLoadingOnPromoCode());
  }
}

export function* receiveFundsFromWebsocketSaga({ payload }: IReceiveFundsAction) {
  const { currencyName, currencyAmount, valueReceived } = payload;
  toastMessageWithElement({ currencyName, currencyAmount, valueReceived });
  history.push('/dashboard');
}

export function* submitAdminCode() {
  const state: IState = yield select();
  const adminCode = getAdminCode(state);
  try {
    yield call(submitAdminCodeRequest, adminCode);
    yield put(setIsAdminCodeValid());
    toast.success(`Successfully saved admin code!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error);
  } finally {
    yield put(stopLoadingAdminCode());
  }
}

export function* resetIndexToDeleteSaga() {
  yield put(toggleLoadingOnResetIndex());
  try {
    const { allNodes, resetCount } = yield call(resetIndexToDeleteRequest);
    resetIndexToastMessage({
      allNodes,
      resetCount,
    });
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error);
  } finally {
    yield put(toggleLoadingOnResetIndex());
  }
}

export function* restoreNodeSaga({ payload }: IRestoreNodeAction) {
  const { deletedNodeId, shouldRestore } = payload;
  try {
    yield call(restoreNodeRequest, {
      deletedNodeId,
      shouldRestore,
    });
    toast.success(`Successfully done!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error);
  } finally {
    yield put(resetRestorationLoadingId());
  }
}

export function* verifyDifferentAccountToTransferFundsSaga({ payload }: IVerifyAccountToTransferFundsAction) {
  const { accountEmail } = payload;
  try {
    yield put(toggleDifferentAccountVerificationLoading());
    const differentAccount: IDifferentAccountData = yield call(verifyDifferentAccountEmailRequest, accountEmail);
    const { username, email } = differentAccount;
    yield put(
      setDifferentAccountToTransferFunds({
        username,
        email,
      })
    );
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleDifferentAccountVerificationLoading());
  }
}

export function* confirmTransferFundsSaga({ payload }: IConfirmTransferAction) {
  const { differentAccountEmail, toggleTransferFundsModal } = payload;
  try {
    yield put(toggleConfirmTransferFundsLoading());
    yield call(confirmTransferFundsRequest, payload);
    transferredFundsMessage(differentAccountEmail);
    toggleTransferFundsModal();
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    if (error.match(TRANSFER_FUNDS_CODE_REGEX)) {
      toast.warning(error, {
        autoClose: 7000,
      });
      yield put(showTransferFundsCodeField());
      return;
    }

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleConfirmTransferFundsLoading());
  }
}

export function* deleteDecommissionDataSaga() {
  try {
    yield put(toggleLoadingDecommissionData());
    yield call(deleteDecommissionDataRequest);
    toast.success(`Decommission data successfully deleted!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleLoadingDecommissionData());
  }
}

export function* deleteDecommissionItemSaga({ payload }: IDeleteDecommissionItemAction) {
  const { decommissionItemId } = payload;
  try {
    yield put(toggleLoadingDecommissionItem(decommissionItemId, 'add'));
    yield call(deleteDecommissionItemRequest, decommissionItemId);
    yield put(removeDecommissionItem(decommissionItemId));
    toast.success(`Decommission item successfully deleted!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleLoadingDecommissionItem(decommissionItemId, 'remove'));
  }
}

export function* showToastMessageSaga({ payload }: IShowToastMessageAction) {
  const { hasError, message, autoClose } = payload;
  if (hasError) {
    toast.error(displayToastMessageWithElements(message), {
      autoClose,
    });
    return;
  }
  toast.success(displayToastMessageWithElements(message), {
    autoClose,
  });
}

export function* updateServerMountingSaga({ payload }: IToggleServerMountingAction) {
  const { server_ip, should_mount } = payload;
  try {
    yield call(updateServerMounting, server_ip, should_mount);
    toast.success(`Successfully toggled mounting for server ${server_ip}!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  }
}

export function* updateServerDeletionSaga({ payload }: IToggleServerDeletionAction) {
  const { server_ip, should_delete } = payload;
  try {
    yield call(updateServerDeletion, server_ip, should_delete);
    toast.success(`Successfully toggled deletion for server ${server_ip}!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  }
}

export function* chargeNodesSaga() {
  try {
    yield put(toggleChargeNodesLoading());
    const totalCharges: number = yield call(chargeNodes);
    toast.success(`Successfully charged: ${totalCharges} nodes!`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleChargeNodesLoading());
  }
}

export function* executeNodeDeletionSaga({ payload }: IExecuteNodeDeletionAction) {
  const { shouldDeleteOnlyReadyNodes } = payload;
  try {
    yield shouldDeleteOnlyReadyNodes ? put(toggleReadyNodeDeletionLoading()) : put(toggleNodeDeletionLoading());
    const totalDeletedNodes: number = yield call(deleteNodesRequest, { shouldDeleteOnlyReadyNodes });
    toast.success(`Successfully deleted: ${totalDeletedNodes} nodes`);
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield shouldDeleteOnlyReadyNodes ? put(toggleReadyNodeDeletionLoading()) : put(toggleNodeDeletionLoading());
  }
}

export function* changeEmailSaga({ payload }: IChangeEmailSaga) {
  try {
    yield put(toggleChangeEmailLoading());
    const { closeModal, code, confirmNewEmail, newEmail } = payload;
    const requestBody = {
      code,
      confirmNewEmail,
      newEmail,
    };
    const { email } = yield call(changeEmailRequest, requestBody);
    yield put(updateEmailAddress(email));
    toast.success(`Successfully changed email address!`);
    closeModal();
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 10000,
    });

    if (String(error).match(CHANGE_EMAIL_CODE_REGEX)) {
      yield put(setShouldShowEmailChangeCodeField(true));
    }
  } finally {
    yield put(toggleChangeEmailLoading());
  }
}

export function* createOnchainPaymentLinkSaga({ payload }: ICreateOnchainPaymentLinkAction) {
  const { showPaymentLinkModalFunction } = payload;
  try {
    yield put(toggleOnchainPaymentLinkLoading());
    const { hosted_url, expires_at } = yield call(createOnchainPaymentLinkRequest);
    yield put(setOnchainPaymentLinkData({ hosted_url, expires_at }));
    showPaymentLinkModalFunction();
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  } finally {
    yield put(toggleOnchainPaymentLinkLoading());
  }
}

export function* dismissPromotionModalSaga({ payload }: IDismissPromotionModalSaga) {
  const { shouldSeePromotionModal, freePriceCode } = payload;
  try {
    yield call(dismissPromotionModalRequest, { shouldSeePromotionModal, freePriceCode });
  } catch (err) {
    const response: any = { err };

    const { data } = response.err.response;

    const { error } = data;

    toast.error(error, {
      autoClose: 7000,
    });
  }
}

export default all([
  takeLatest(userActionTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(userActionTypes.DISMISS_PROMOTION_MODAL, dismissPromotionModalSaga),
  takeLatest(userActionTypes.GET_NODE_DATA, getNodeDataSaga),
  takeLatest(userActionTypes.GET_ADMIN_DATA, getAdminDataSaga),
  takeLatest(userActionTypes.TOGGLE_NOTIFICATION_WARNING_MAIL, toggleNotificationWarningSaga),
  takeLatest(userActionTypes.GET_USER_BALANCES, getUserBalances),
  takeLatest(userActionTypes.RESET_INDEX_TO_DELETE, resetIndexToDeleteSaga),
  takeLatest(userActionTypes.SUBMIT_ADMIN_CODE, submitAdminCode),
  takeLatest(userActionTypes.REFRESH_USER_BALANCES, getUserBalances),
  takeLatest(userActionTypes.SEND_PROMOTION_CODE, sendPromotionCodeSaga),
  takeLatest(userActionTypes.RESTORE_NODE, restoreNodeSaga),
  takeLatest(userActionTypes.SHOW_TOAST_MESSAGE, showToastMessageSaga),
  takeLatest(userActionTypes.RECEIVE_FUNDS, receiveFundsFromWebsocketSaga),
  takeLatest(userActionTypes.DELETE_DECOMMISSION_DATA, deleteDecommissionDataSaga),
  takeLatest(userActionTypes.DELETE_DECOMMISSION_ITEM, deleteDecommissionItemSaga),
  takeLatest(
    userActionTypes.UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_REQUEST,
    updateMasternodesToStartNotification
  ),
  takeLatest(userActionTypes.VERIFY_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS, verifyDifferentAccountToTransferFundsSaga),
  takeLatest(userActionTypes.CONFIRM_TRANSFER_FUNDS, confirmTransferFundsSaga),
  takeLatest(userActionTypes.TOGGLE_SERVER_MOUNTING, updateServerMountingSaga),
  takeLatest(userActionTypes.TOGGLE_SERVER_DELETION, updateServerDeletionSaga),
  takeLatest(userActionTypes.CHARGE_NODES, chargeNodesSaga),
  takeLatest(userActionTypes.EXECUTE_NODE_DELETION, executeNodeDeletionSaga),
  takeLatest(userActionTypes.CHANGE_EMAIL, changeEmailSaga),
  takeLatest(userActionTypes.CREATE_ONCHAIN_PAYMENT_LINK, createOnchainPaymentLinkSaga),
]);
