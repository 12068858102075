import uuid from 'react-uuid';
import { ReactElement, useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';

import { StyledTooltipReactBootstrap, GlobalStylesForTooltip, TooltipContainer } from './styles';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface ITooltip {
  children: ReactElement;
  content: string | ReactElement;
  placement?: Placement;
  forcedWidth?: number;
}

const renderTooltipContent = (content: string | ReactElement) => {
  const contentRef = useRef(null);
  return (
    <StyledTooltipReactBootstrap ref={contentRef} id={uuid()}>
      {content}
    </StyledTooltipReactBootstrap>
  );
};

export function Tooltip({ children, content, forcedWidth, ...props }: ITooltip) {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  const showTooltip = () => {
    setShouldShowTooltip(true);
  };

  const hideTooltip = () => {
    setShouldShowTooltip(false);
  };

  return (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <GlobalStylesForTooltip styles={{ forcedWidth }} />
      <OverlayTrigger
        {...props}
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltipContent(content)}
        show={shouldShowTooltip}
      >
        {children}
      </OverlayTrigger>
    </TooltipContainer>
  );
}
