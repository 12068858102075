import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegram, FaDiscord } from 'react-icons/fa';
import { Container, SocialMediaLink } from './styles';

export function Help({ ...props }) {
  return (
    <Container {...props}>
      <SocialMediaLink rel="noopener noreferrer" href="https://t.me/officialhiglan" target="_blank">
        <FaTelegram size={30} />
        Telegram
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://discord.gg/RVdp6Ac6g4" target="_blank">
        <FaDiscord size={30} />
        Discord
      </SocialMediaLink>
      <SocialMediaLink rel="noopener noreferrer" href="https://twitter.com/officialhiglan" target="_blank">
        <FaXTwitter size={30} />
        Twitter
      </SocialMediaLink>
    </Container>
  );
}
