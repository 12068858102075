import { authActionTypes } from '../auth/actions';
import { userActionTypes, IUserAction, INotificationWarningType } from './actions';

const INITIAL_STATE = {
  profile: {
    balances: {
      balance: 0,
      bonus: 0,
    },
  },
  loading: false,
  nodeData: {
    coinData: [],
    generalData: {
      charges: {
        paid: 0,
        willPay: 0,
      },
      status: {
        activated: 0,
        mounting: 0,
        ready: 0,
        total: 0,
      },
      overdueCharges: {
        balanceNeeded: 0,
        totalOverdueCharges: 0,
      },
    },
  },
  isLoadingForResetIndexToDelete: false,
  restorationLoadingId: '',
  isRefreshingAdminData: false,
  isLoadingDifferentAccountVerification: false,
  isLoadingConfirmTransferFunds: false,
  isLoadingChargeNodes: false,
  isLoadingNodeDeletion: false,
  isLoadingReadyNodeDeletion: false,
  differentAccountToTransferFunds: null,
  shouldShowTransferFundsCodeField: false,
  isDeletingDecommissionData: false,
  isLoadingChangeEmail: false,
  isLoadingOnchainPaymentLink: false,
  newEmail: '',
  confirmNewEmail: '',
  shouldShowEmailChangeCodeField: false,
  loadingIdsForDeletingDecommissionItems: [],
  onchainPaymentLinkData: {
    hosted_url: '',
    expires_at: '',
  },
};

export const user = (state = INITIAL_STATE, action: IUserAction) => {
  const { type, payload } = action;

  switch (type) {
    case authActionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload.user,
        },
      };
    }
    case userActionTypes.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload.profile,
        },
        loading: false,
      };
    }
    case userActionTypes.UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case userActionTypes.UPDATE_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case userActionTypes.UPDATE_PROFILE_MASTERNODE_TO_START_NOTIFICATION_SUCCESS: {
      const { masternode_to_start_notification } = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          masternode_to_start_notification,
        },
      };
    }
    case userActionTypes.SET_NODE_DATA: {
      const { nodeData } = action.payload;

      return {
        ...state,
        nodeData,
      };
    }
    case userActionTypes.SUBMIT_ADMIN_CODE: {
      return {
        ...state,
        isSubmittingAdminCode: true,
      };
    }
    case userActionTypes.SET_ADMIN_DATA: {
      const { adminData } = action.payload;

      return {
        ...state,
        adminData,
      };
    }
    case userActionTypes.UPDATE_NOTIFICATION_WARNING_MAIL: {
      const { type, isEnabled } = action.payload;
      const notificationType: INotificationWarningType = type;
      const stateCopy: any = {
        ...state,
      };

      switch (notificationType) {
        case 'decommision_warning_mail': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              warnings: {
                ...stateCopy.adminData.warnings,
                decommissionedNodes: {
                  ...stateCopy.adminData.warnings.decommissionedNodes,
                  is_decommission_nodes_warning_mail_enabled: isEnabled,
                },
              },
            },
          };
        }
        case 'delayed_blocks_warning_mail': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              warnings: {
                ...stateCopy.adminData.warnings,
                delayedBlockNodes: {
                  ...stateCopy.adminData.warnings.delayedBlockNodes,
                  is_delayed_blocks_warning_mail_enabled: isEnabled,
                },
              },
            },
          };
        }
        case 'script_warning_mail': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              warnings: {
                ...stateCopy.adminData.warnings,
                scriptWarnings: {
                  ...stateCopy.adminData.warnings.scriptWarnings,
                  is_script_warning_mail_enabled: isEnabled,
                },
              },
            },
          };
        }
        case 'charge_warning_mail': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              allServers: {
                ...stateCopy.adminData.allServers,
                is_charge_warning_mail_enabled: isEnabled,
              },
            },
          };
        }
        case 'is_running_general_deletion': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              allServers: {
                ...stateCopy.adminData.allServers,
                is_running_general_deletion: isEnabled,
              },
            },
          };
        }
        case 'should_allow_user_delete_nodes': {
          return {
            ...stateCopy,
            adminData: {
              ...stateCopy.adminData,
              allServers: {
                ...stateCopy.adminData.allServers,
                should_allow_user_deletion: isEnabled,
              },
            },
          };
        }
      }
    }
    case userActionTypes.RESET_ADMIN_CODE_DATA: {
      return {
        ...state,
        adminCode: '',
        isAdminCodeValid: false,
        isSubmittingAdminCode: false,
      };
    }
    case userActionTypes.STOP_LOADING_ADMIN_CODE: {
      return {
        ...state,
        isSubmittingAdminCode: false,
      };
    }
    case userActionTypes.TOGGLE_LOADING_ON_RESET_INDEX: {
      return {
        ...state,
        isLoadingForResetIndexToDelete: !state.isLoadingForResetIndexToDelete,
      };
    }
    case userActionTypes.RESTORE_NODE: {
      const { restorationLoadingId } = action.payload;
      return {
        ...state,
        restorationLoadingId,
      };
    }
    case userActionTypes.RESET_RESTORATION_LOADING_ID: {
      return {
        ...state,
        restorationLoadingId: '',
      };
    }
    case userActionTypes.TOGGLE_LOADING_ADMIN_DATA: {
      return {
        ...state,
        isRefreshingAdminData: !state.isRefreshingAdminData,
      };
    }
    case userActionTypes.STOP_LOADING_NOTIFICATION_WARNING_MAIL: {
      return {
        ...state,
        isRefreshingAdminData: false,
      };
    }
    case userActionTypes.SET_ADMIN_CODE: {
      const { code } = action.payload;

      return {
        ...state,
        adminCode: code,
      };
    }
    case userActionTypes.SET_IS_ADMIN_CODE_VALID: {
      return {
        ...state,
        isAdminCodeValid: true,
      };
    }
    case userActionTypes.SET_USER_BALANCES: {
      return {
        ...state,
        profile: {
          ...state.profile,
          balances: {
            ...payload,
          },
        },
      };
    }
    case userActionTypes.SEND_PROMOTION_CODE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          isSendingPromoCode: true,
        },
      };
    }
    case userActionTypes.STOP_LOADING_ON_PROMOTION_CODE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          isSendingPromoCode: false,
        },
      };
    }
    case userActionTypes.TOGGLE_DIFFERENT_ACCOUNT_VERIFICATION_LOADING: {
      const { isLoadingDifferentAccountVerification } = state;
      return {
        ...state,
        isLoadingDifferentAccountVerification: !isLoadingDifferentAccountVerification,
      };
    }
    case userActionTypes.TOGGLE_CONFIRM_TRANSFER_FUNDS_LOADING: {
      const { isLoadingConfirmTransferFunds } = state;
      return {
        ...state,
        isLoadingConfirmTransferFunds: !isLoadingConfirmTransferFunds,
      };
    }
    case userActionTypes.TOGGLE_CHARGE_NODES_LOADING: {
      const { isLoadingChargeNodes } = state;
      return {
        ...state,
        isLoadingChargeNodes: !isLoadingChargeNodes,
      };
    }
    case userActionTypes.TOGGLE_NODE_DELETION_LOADING: {
      const { isLoadingNodeDeletion } = state;
      return {
        ...state,
        isLoadingNodeDeletion: !isLoadingNodeDeletion,
      };
    }
    case userActionTypes.TOGGLE_READY_NODE_DELETION_LOADING: {
      const { isLoadingReadyNodeDeletion } = state;
      return {
        ...state,
        isLoadingReadyNodeDeletion: !isLoadingReadyNodeDeletion,
      };
    }
    case userActionTypes.SET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: {
      return {
        ...state,
        differentAccountToTransferFunds: {
          ...payload,
        },
      };
    }
    case userActionTypes.SHOW_TRANSFER_FUNDS_CODE_FIELD: {
      return {
        ...state,
        shouldShowTransferFundsCodeField: true,
      };
    }
    case userActionTypes.RESET_DIFFERENT_ACCOUNT_TO_TRANSFER_FUNDS: {
      return {
        ...state,
        differentAccountToTransferFunds: null,
        shouldShowTransferFundsCodeField: false,
      };
    }
    case userActionTypes.TOGGLE_LOADING_DELETING_DECOMMISSION_DATA: {
      const { isDeletingDecommissionData } = state;
      return {
        ...state,
        isDeletingDecommissionData: !isDeletingDecommissionData,
      };
    }
    case userActionTypes.TOGGLE_LOADING_DELETING_DECOMMISSION_ITEM: {
      const { decommissionItemId, type } = payload;
      const { loadingIdsForDeletingDecommissionItems } = state;

      let currentIdsForLoading: number[] = [];

      switch (type) {
        case 'add': {
          currentIdsForLoading = [...loadingIdsForDeletingDecommissionItems, decommissionItemId];
          break;
        }
        case 'remove': {
          currentIdsForLoading = loadingIdsForDeletingDecommissionItems.filter(
            (idNumber) => idNumber !== decommissionItemId
          );
          break;
        }
        default: {
          currentIdsForLoading = [];
          break;
        }
      }

      return {
        ...state,
        loadingIdsForDeletingDecommissionItems: currentIdsForLoading,
      };
    }
    case userActionTypes.REMOVE_DECOMMISSION_ITEM: {
      const { decommissionItemId } = payload;
      const copiedState: any = { ...state };
      const { decommissionHistory } = copiedState.adminData;

      const currentDecommissionItems = decommissionHistory.filter(
        (decommissionItem: any) => decommissionItem.id !== decommissionItemId
      );

      return {
        ...state,
        adminData: {
          ...copiedState.adminData,
          decommissionHistory: currentDecommissionItems,
        },
      };
    }
    case userActionTypes.TOGGLE_SERVER_MOUNTING: {
      const stateCopy: any = { ...state };
      const { adminData } = stateCopy;
      const { server_ip, should_mount } = payload;

      const currentServersState = adminData.allServers.servers.map((serverItem: any) => {
        if (serverItem.ip === server_ip && should_mount) {
          return {
            ...serverItem,
            is_mounting: true,
          };
        }

        return {
          ...serverItem,
          is_mounting: false,
        };
      });

      return {
        ...stateCopy,
        adminData: {
          ...stateCopy.adminData,
          allServers: {
            ...stateCopy.adminData.allServers,
            servers: [...currentServersState],
          },
        },
      };
    }
    case userActionTypes.TOGGLE_SERVER_DELETION: {
      const stateCopy: any = { ...state };
      const { adminData } = stateCopy;
      const { server_ip, should_delete } = payload;

      const serverToUpdate = adminData.allServers.servers.find((serverItem: any) => serverItem.ip === server_ip);

      if (serverToUpdate) {
        serverToUpdate.is_deleting = should_delete;
      }

      return {
        ...stateCopy,
        adminData: {
          ...stateCopy.adminData,
        },
      };
    }
    case userActionTypes.TOGGLE_CHANGE_EMAIL_LOADING: {
      return {
        ...state,
        isLoadingChangeEmail: !state.isLoadingChangeEmail,
      };
    }
    case userActionTypes.SET_ONCHAIN_PAYMENT_LINK_DATA: {
      const { hosted_url, expires_at } = payload;
      return {
        ...state,
        onchainPaymentLinkData: {
          hosted_url,
          expires_at,
        },
      };
    }
    case userActionTypes.TOGGLE_ONCHAIN_PAYMENT_LINK_LOADING: {
      return {
        ...state,
        isLoadingOnchainPaymentLink: !state.isLoadingOnchainPaymentLink,
      };
    }
    case userActionTypes.UPDATE_EMAIL_ADDRESS: {
      const { email } = payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          email,
        },
      };
    }
    case userActionTypes.SET_EMAILS_FOR_CHANGE_EMAIL: {
      const { newEmail, confirmNewEmail } = payload;
      return {
        ...state,
        newEmail,
        confirmNewEmail,
      };
    }
    case userActionTypes.RESET_EMAIL_CHANGE_VALUES: {
      return {
        ...state,
        newEmail: '',
        confirmNewEmail: '',
      };
    }
    case userActionTypes.SET_SHOULD_SHOW_EMAIL_CHANGE_CODE_FIELD: {
      const { shouldShowEmailChangeCodeField } = payload;
      return {
        ...state,
        shouldShowEmailChangeCodeField: shouldShowEmailChangeCodeField,
      };
    }
    case authActionTypes.SIGN_OUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
};
